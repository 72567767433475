<template>
    <div class="row">
      <div class="col-md-12">
        <div class="bgc-white bd bdrs-3 p-20 mB-20">
          <h2 class="c-grey-900 mB-20" style="display: inline-block">
            امتحانات {{subject}}
          </h2>
          <span style="border-right: 1px solid #ddd; margin: 0 10px"></span>
          <button class="btn btn-sm btn-success" @click="addExam">
            <i class="ti-plus mL-5" style="color: #fff"></i>إضافة امتحان
          </button>
          <div id="dataTable_wrapper" class="dataTables_wrapper">
            <form @submit="checkForm">
              <div class="form-group row pB-30 pT-30">
                <div class="col-sm-3">
                  <input
                    type="number"
                    class="form-control"
                    v-model="examId"
                    placeholder="رقم الامتحان"
                  />
                </div>
                <div class="col-sm-3">
                  <input
                    type="text"
                    class="form-control"
                    v-model="examName"
                    placeholder="إسم الامتحان"
                  />
                </div>
                <div class="col-sm-2">
                  <button
                    type="submit"
                    class="btn btn-primary mL-10"
                    :disabled="loading"
                  >
                    {{ $t("search") }}
                  </button>
                </div>
              </div>
            </form>
            <table
              id="dataTable"
              class="table table-bordered dataTable table-hover"
              role="grid"
              aria-describedby="dataTable_info"
              style="width: 100%"
              width="100%"
              cellspacing="0"
            >
              <thead>
                <tr role="row">
                  <th
                    class="sorting_asc"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    aria-sort="ascending"
                    aria-label="Name: activate to sort column descending"
                  >
                    #
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    aria-label="Position: activate to sort column ascending"
                  >
                    {{ $t("name") }}
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    style="width: 50px"
                    aria-label="Start date: activate to sort column ascending"
                  >
                    تعديل 
                  </th>
                  <th
                    class="sorting"
                    tabindex="0"
                    aria-controls="dataTable"
                    rowspan="1"
                    colspan="1"
                    style="width: 50px"
                    aria-label="Start date: activate to sort column ascending"
                  >
                    حذف
                  </th>
                </tr>
              </thead>
              <tbody v-show="!loading" v-for="item in exams" :key="item.id">
                <tr role="row" class="odd">
                  <td class="sorting_1">{{ item.id }}</td>
                  <td>
                    <a :href="'https://www.yalla-emtihan.com/yalla-emtihan/public/docs/books/'+item.exam" target="_blank">{{item.ar_name }}</a>
                  </td>
                  <td>
                  <a href="javascript:"
                  @click="editExam(item)"
                    ><span class="badge rounded-pill bgc-amber-50 c-amber-700"
                      ><i class="ti-pencil"></i></span
                  ></a>
                  </td>
                  <td>
                    <a href="javascript:"
                    @click="deleteExam(item)"
                      ><span class="badge rounded-pill c-red-500"
                        ><i class="ti-trash"></i></span
                    ></a>
                  </td>
                </tr>
              </tbody>
            </table>
            <loading v-show="loading" />
            <p v-show="!exams.length" style="text-align: center">
              {{ $t("noData") }}
            </p>
            <div style="text-align:center;margin-top:40px">
            <pagination style="display:inline-block;text-align:center" v-model="page" :records="pagination.total ?pagination.total : 2 " :per-page="10" @paginate="getExams"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
      props:{
      id: {
        type: String,
        required: true
      },
      subject: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        loading: true,
        examId: "",
        examName: "",
        exams: [],
        pagination: {},
        page:1,
      };
    },
    created() {
      this.getExams();
    },
    methods: {
      checkForm: function (e) {
        e.preventDefault();
        this.getExams();
      },
      getExams: function () {
        this.loading = true;
        this.$http
          .get("https://yalla-emtihan.com/backend/subjects/getExams", {
            headers: {
              Authorization: "Bearer " + sessionStorage.getItem("token"),
            },
            params: {
              subject_id: this.id,
              page: this.page,
              examName: this.examName,
              examId: this.examId
            },
          })
          .then(
            function (data) {
              this.loading = false;
              switch (data.status) {
                case 200:
                  this.exams = data.body.data;
                  this.pagination = data.body;
                  console.log(this.pagination);
                  break;
              }
            },
            (err) => {
              this.loading = false;
              switch (err.status) {
                case 500:
                  this.error(this.$t("canNotAccess"));
                  break;
                case 401:
                  this.error(this.$t("verificationProcessError"));
                  localStorage.clear();
                  sessionStorage.clear();
                  this.$router.push("/login");
                  break;
                default:
                  this.error(this.$t("unexpectedError"));
                  break;
              }
            }
          );
      },
      deleteExam: function (exam) {

let headers = {
  Authorization: "Bearer " + sessionStorage.getItem("token"),
};
this.$http
  .post(
    "https://yalla-emtihan.com/backend/subjects/deleteExam",
    {
      exam_id : exam.id,
      exam: exam.exam
    },
    { headers }
  )

  .then(
    function (data) {
      this.loading = false;
      switch (data.status) {
        case 200:
          this.success(this.$t("success"));
          this.getExams();
          break;
      } 
    },
    (err) => {
      this.loading = false;
      switch (err.status) {
        case 500:
          this.error(this.$t("canNotAccess"));
          break;
        case 401:
          this.error(this.$t("verificationProcessError"));
          localStorage.clear();
          sessionStorage.clear();
          this.$router.push("/login");
          break;
        default:
          this.error(this.$t("unexpectedError"));
      }
    }
  );
},
      addExam: function () {
        const panel1Handle = this.$showPanel({
          component: () => import("../../classes/subjects/AddExam.vue"),
          openOn: "right",
          width: 700,
          disableBgClick: true,
          props: {
            reload:this.getExams,
            id: this.id
          },
        });
      },
      editExam(Exam) {
        const panel1Handle = this.$showPanel({
          component: () => import("../../classes/subjects/EditExam.vue"),
          openOn: "right",
          width: 700,
          disableBgClick: true,
          props: {
            examModel: Exam,
            reload:this.getExams
          },
        });
  
        panel1Handle.promise.then((result) => {});
      },
      error: function (message) {
        this.$toast.error(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      warning: function (message) {
        this.$toast.warning(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      success: function (message) {
        this.$toast.success(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
    },
  };
  </script>
  
  <style>
  </style>